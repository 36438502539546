import { Box, Flex, IconButton, Text } from '@chakra-ui/react';
import { type MoodTrackingResultDto } from '@innerwell/dtos';
import { DateTime } from 'luxon';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useMemo } from 'react';

import useThemedToast from '@/hooks/useThemedToast';

import { Icon } from '../Icon';

interface IProps {
   days: MoodTrackingResultDto[];
   onStartMoodTracking: (date: string) => void;
}

const MoodTrackingMessages = [
   `You’ll learn a lot about yourself by tracking your emotions and moods, and
   it’s a powerful way to take care of your mental health.`,

   `No matter how you feel, we’re here for you. By tracking your moods over time,
   your Care Team will gain a deeper understanding of your needs.`,

   `Did you know that the physiological lifespan of an emotion in the brain is
   90 seconds? Identifying an emotion can help you let go of it.`,

   `By regularly tracking your moods, you’ll more clearly see the relationship
   between your thoughts, emotions, and behavior.`,

   `Remember to take time during the day to check in with how you’re feeling.
   Creating this space can help you identify emotional triggers.`,

   `Emotions ebb and flow, whereas moods are more persistent. Daily mood tracking
   allows you to see the connection between your emotions and moods.`,

   `Labeling your emotions regularly helps you become more in touch with them.
   Acknowledging your moods daily helps you better understand them.`,
];

const MoodTrackingWidget: React.FC<IProps> = ({
   days,
   onStartMoodTracking,
}) => {
   const router = useRouter();
   const pathname = usePathname();
   const searchParams = useSearchParams();
   const { toastSuccess } = useThemedToast();

   const daysTracked = useMemo(
      () =>
         days.reduce((acc, day) => {
            return acc + (day.score ? 1 : 0);
         }, 0),
      [days],
   );

   const isTodayFilledIn = useMemo(() => {
      const currentDatetime = DateTime.local();
      const today = currentDatetime.toISODate();
      const day = days.find((d) => d.date === today);
      return day?.score ? true : false;
   }, [days]);

   useEffect(() => {
      if (searchParams.get('open') === 'daily-mood-tracking') {
         if (!isTodayFilledIn) {
            onStartMoodTracking(DateTime.local().toISODate());
         } else {
            toastSuccess("You've already tracked your daily mood today!");
         }

         const nextSearchParams = new URLSearchParams(searchParams.toString());
         nextSearchParams.delete('open');

         router.replace(`/?${nextSearchParams.toString()}`);
      }
   }, [
      isTodayFilledIn,
      onStartMoodTracking,
      pathname,
      router,
      searchParams,
      toastSuccess,
   ]);

   return (
      <Flex
         alignItems="center"
         bg="rgba(255, 255, 255, 0.1)"
         borderRadius={12}
         mb={6}
         pr={{ lg: 6 }}
         px={{ base: 4, lg: 7 }}
         gap={{ base: 2, lg: 8 }}
         flexDir={{ base: 'column', lg: 'row' }}
      >
         <Box w="full" flex={1} minW={{ lg: '450px' }}>
            <Flex
               direction={{ base: 'column', lg: 'row' }}
               pt={{ base: 4, lg: 6 }}
               pb={{ base: 4, lg: 6 }}
               justifyContent="space-between"
            >
               <Text size="captionSmall" letterSpacing={0.5}>
                  Daily mood tracking
               </Text>
               <Text size={{ base: 'paragraphSmall', lg: 'paragraph' }}>
                  {daysTracked} of 7 this week
               </Text>
            </Flex>

            <Flex pb={4} justifyContent="space-between">
               {days.map((day) => {
                  const currentDay = DateTime.fromISO(day.date).hasSame(
                     DateTime.local(),
                     'day',
                  );

                  const currentDayIsFilledIn = currentDay && day.score;

                  return (
                     <Flex
                        key={day.date}
                        direction="column"
                        alignItems="center"
                        justifyContent="space-between"
                     >
                        {currentDay && !currentDayIsFilledIn ? (
                           <IconButton
                              icon={
                                 <Icon
                                    name="plus-pin"
                                    w={{ base: '2.62rem', lg: '4.37rem' }}
                                    h={{ base: '3.37rem', lg: '4.75rem' }}
                                    color="accent.orange"
                                 />
                              }
                              variant="link"
                              aria-label="days"
                              mb={-4}
                              top={-3}
                              onClick={() => onStartMoodTracking(day.date)}
                           />
                        ) : (
                           <Flex
                              alignItems="center"
                              justifyContent="center"
                              width={{ base: '30px', lg: '44px' }}
                              height={{ base: '30px', lg: '44px' }}
                              bg={
                                 day.score
                                    ? '#9DE3E3'
                                    : 'rgba(18, 23, 35, 0.35)'
                              }
                              borderRadius="50%"
                              mb={2}
                           >
                              {day.score ? (
                                 <Icon
                                    name="checkmark"
                                    w={{ base: 4, lg: 5 }}
                                    h={{ base: 4, lg: 5 }}
                                    color="black"
                                 />
                              ) : null}
                           </Flex>
                        )}

                        <Text
                           display={{ base: 'none', lg: 'block' }}
                           fontSize="xxs"
                           fontWeight={600}
                        >
                           {DateTime.fromISO(day.date).toFormat('cccc')}
                        </Text>
                        <Text
                           display={{ lg: 'none' }}
                           fontSize="xxs"
                           fontWeight={600}
                        >
                           {DateTime.fromISO(day.date).toFormat('ccccc')}
                        </Text>
                     </Flex>
                  );
               })}
            </Flex>
         </Box>
         {!isTodayFilledIn && (
            <Box
               maxW={{ lg: '270px' }}
               w="full"
               p={3}
               mt={{ lg: 4 }}
               mb={{ base: 4, lg: 4 }}
               borderRadius={6}
               bg="whiteAlpha.200"
            >
               <Text
                  size={{ base: 'paragraphSmall', xl: 'paragraph' }}
                  fontStyle="italic"
               >
                  {MoodTrackingMessages[daysTracked + 1]}
               </Text>
            </Box>
         )}
      </Flex>
   );
};

export default MoodTrackingWidget;
