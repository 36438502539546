import {
   MissedAppointmentFeePaid,
   type MissedAppointmentsResponseDto,
   SCHEDULING_ALLOWED_RESCHEDULING_BEFORE_APPOINTMENT_HOURS,
} from '@innerwell/dtos';

import { MissedAppointmentGraphic } from '@/components/Images';

import { Card } from '../components';

export const PatientMissedAppointmentCard = ({
   missedAppointment,
}: {
   missedAppointment: MissedAppointmentsResponseDto;
}) => {
   const isPending =
      missedAppointment.status === MissedAppointmentFeePaid.Pending;

   const ctaLink =
      missedAppointment.type === 'missed'
         ? (`/missed-appointment/${missedAppointment.id}` as const)
         : (`/cancel-appointment/${missedAppointment.id}` as const);

   return (
      <Card minH="none" bg="background.secondary">
         <Card.Title
            fontWeight={600}
            display="flex"
            gap={3}
            alignItems="center"
         >
            <MissedAppointmentGraphic
               w={{ base: '60px', lg: '80px' }}
               h={{ base: '60px', lg: '80px' }}
            />
            {missedAppointment.type === 'missed'
               ? `We’re sorry we missed you.`
               : 'Outstanding Balance Notice'}
         </Card.Title>
         {missedAppointment.type === 'missed' ? (
            <Card.Text fontSize="lg" pr={4}>
               Reminder: there is a $100 fee for missing your appointment, or
               canceling/rescheduling within{' '}
               {SCHEDULING_ALLOWED_RESCHEDULING_BEFORE_APPOINTMENT_HOURS} hours
               of its start time. This fee is charged out of respect for our
               clinicians’ time. <br />
               <br />
               Our Member Support team will notify you about rescheduling once
               payment has been made.
            </Card.Text>
         ) : (
            <Card.Text fontSize="lg" pr={4}>
               It looks like there’s an outstanding balance from a recent
               appointment cancellation. Please settle this fee to unlock your
               ability to schedule new appointments. If you have any questions
               or need assistance, we’re here to help. Thank you for your
               understanding.
            </Card.Text>
         )}
         <Card.ButtonGroup>
            <Card.ButtonLink isDisabled={isPending} href={ctaLink}>
               {isPending
                  ? 'Payment is pending'
                  : missedAppointment.type === 'missed'
                    ? 'Make Payment'
                    : 'Pay Balance Now'}
            </Card.ButtonLink>
         </Card.ButtonGroup>
      </Card>
   );
};
