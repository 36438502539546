import { Text } from '@chakra-ui/react';
import { type FailedPaymentDto, FailedPaymentsRetryStatus } from '@innerwell/dtos';

import { Card } from '../components';
import { ErrorCard } from '../ErrorCard';
import { TroubleWithPaymentGraphic } from '../../Images';

export const PatientPaymentFailedCard = ({
   paymentFailedCdt,
}: {
   paymentFailedCdt: FailedPaymentDto;
}) => {
   const { paymentFailedCdtId: cdtId, retryStatus: status } = paymentFailedCdt;

   if (status === FailedPaymentsRetryStatus.PENDING) {
      return (
         <ErrorCard
            text={
               <Text size="paragraphMedium" fontWeight={500}>
                  We have received your payments. Processing may take up to 1
                  day.
                  <br />
                  For any questions or issues with the platform, please reach
                  out to Member Support.
               </Text>
            }
            maxWidth="auto"
         />
      );
   }

   return (
      <Card bg="background.secondary">
         <Card.Title
            fontWeight={600}
            display="flex"
            gap={3}
            alignItems="center"
         >
            <TroubleWithPaymentGraphic
               w={{ base: '60px', lg: '80px' }}
               h={{ base: '60px', lg: '80px' }}
            />
            {`We're having some trouble collecting your latest payment.`}
         </Card.Title>
         <Card.Text fontSize="lg" pr={4}>
            {`Your latest payment didn't go through. We must cancel
            any upcoming appointments until your outstanding balance is resolved.`}
            <br />
            {`Please pay the outstanding balance to avoid further interruptions.
            If you have any questions, feel free to reach out to Member Support.`}
         </Card.Text>
         <Card.ButtonGroup>
            <Card.ButtonLink href={`/retry-payment/${cdtId}`}>
               Pay now
            </Card.ButtonLink>

            <Card.ButtonLink href="/support" variant="link">
               Contact Member Support
            </Card.ButtonLink>
         </Card.ButtonGroup>
      </Card>
   );
};
