import {
   Box,
   type BoxProps,
   Flex,
   IconButton,
   Text,
   Textarea,
   useDisclosure,
   type UseDisclosureReturn,
   useRadioGroup,
} from '@chakra-ui/react';
import { NextImage } from '@innerwell/chakra/images';
import { type MoodTrackingEnum } from '@innerwell/dtos';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Axios from 'axios';
import { DateTime } from 'luxon';
import { useState, useEffect, useMemo, useRef } from 'react';

import useThemedToast from '@/hooks/useThemedToast';

import BoxedModal from '@/components/Modals/BoxedModal';

import { webApiClient } from '@/api-client/apiClient';
import { useSession } from '@/contexts/session-context';
import { handleSentryException } from '@/utils/sentry';
import { WEEKLY_MOOD_OPTIONS } from '@/utils/weekly-mood-options';

import EmotionRadioCheckbox from '../Devkit/EmotionRadioCheckbox/EmotionRadioCheckbox';
import { Icon } from '../Icon';

import { queryKeys } from '@/types/query-keys';

interface IProps extends BoxProps {
   initialMoodTrackingModal: UseDisclosureReturn;
   onFinishedDailyMoodTracking: (score: MoodTrackingEnum) => void;
   date: string | null;
}

const DailyMoodTrackingModal: React.FC<IProps> = ({
   initialMoodTrackingModal,
   onFinishedDailyMoodTracking,
   date,
   ...props
}) => {
   const { toastError } = useThemedToast();

   const howYouFeelTrackingModal = useDisclosure();
   const inputHowYouFeelTrackingModal = useDisclosure();
   const successMoodTrackingModal = useDisclosure();
   const [detailsInput, setDetailsInput] = useState('');
   const textareaRef = useRef<HTMLTextAreaElement>(null);
   const dateDailyMoodStarted = useRef<string | null>(null);
   const queryClient = useQueryClient();
   const { data: sessionData } = useSession();

   // If its the first time the user is tracking their mood, get the time the user opened the first modal
   useEffect(() => {
      if (initialMoodTrackingModal.isOpen && date === null) {
         dateDailyMoodStarted.current = DateTime.local().toISODate();
      }
   }, [initialMoodTrackingModal.isOpen, date]);

   const handleDetailsInputChange = (
      e: React.ChangeEvent<HTMLTextAreaElement>,
   ) => {
      setDetailsInput(e.target.value);
   };

   const handleStartModalNext = () => {
      initialMoodTrackingModal.onClose();
      howYouFeelTrackingModal.onOpen();
   };

   // Open details modal after selecting the mood
   const handleSelectedMood = () => {
      howYouFeelTrackingModal.onClose();
      inputHowYouFeelTrackingModal.onOpen();
   };

   // If the user goes back from details screen to the mood screen
   const handleBackToMoodSelection = () => {
      inputHowYouFeelTrackingModal.onClose();
      howYouFeelTrackingModal.onOpen();
   };

   // Open success modal
   const handleDetailsModalNext = () => {
      trackMood();
   };

   const onFinishMoodTrackingModals = () => {
      successMoodTrackingModal.onClose();
      if (currentMoodInfo) {
         onFinishedDailyMoodTracking(currentMoodInfo.value);
      }
   };

   // Setup radio box
   const {
      value: selectedMood,
      getRadioProps,
      getRootProps,
   } = useRadioGroup({
      name: 'mood',
      defaultValue: '',
   });

   const currentMoodInfo = useMemo(
      () =>
         WEEKLY_MOOD_OPTIONS.find(
            (opt) => opt.value.toString() === selectedMood,
         ),
      [selectedMood],
   );

   // Track mood
   const { mutate: trackMood, status: trackMoodStatus } = useMutation({
      mutationFn: () => {
         if (!currentMoodInfo) {
            throw new Error('No mood selected');
         }

         const getDate = () => {
            if (date) {
               return DateTime.fromISO(date, { zone: 'utc' });
            }
            if (dateDailyMoodStarted.current) {
               return DateTime.fromISO(dateDailyMoodStarted.current, {
                  zone: 'utc',
               });
            }

            return DateTime.now().toUTC();
         };

         return webApiClient.moodTracking.createMoodTracking({
            body: {
               score: currentMoodInfo.value,
               details: detailsInput,
               date: getDate().toISO(),
            },
         });
      },
      onError: (err) => {
         handleSentryException(err);

         if (Axios.isAxiosError(err)) {
            toastError(err.message);
         }
      },
      onSuccess: () => {
         inputHowYouFeelTrackingModal.onClose();
         successMoodTrackingModal.onOpen();

         queryClient.refetchQueries({ queryKey: queryKeys.dailyMoods });
         queryClient.refetchQueries({ queryKey: queryKeys.moodTracking });
      },
   });

   return (
      <Box {...props}>
         <BoxedModal
            title="Daily Mood Tracking"
            {...initialMoodTrackingModal}
            onCTAClick={handleStartModalNext}
            ctaLabel="Next"
            textAlign="center"
            fullWidthBtn
            description={
               <Text size="paragraphLarge" pt={6}>
                  Find a quiet space to sit down, relax and be one with
                  yourself. When you’re ready, step away from your thoughts and
                  connect with how you’re feeling in this moment.
               </Text>
            }
         >
            <Flex
               zIndex={0}
               position="absolute"
               bottom={0}
               right={0}
               top={0}
               left={0}
            >
               <NextImage
                  w="100%"
                  h="100%"
                  src="/images/modal-stones.jpg"
                  objectFit="cover"
                  alt="bg-image"
               />
            </Flex>
         </BoxedModal>

         {/* How you Feel racking Modal */}
         <BoxedModal
            bg="accent.sand"
            description=""
            title="I feel..."
            closeButtonBg="white"
            removeHorizontalPadding
            {...howYouFeelTrackingModal}
         >
            <Flex {...getRootProps()} flexDir="column">
               <Box>
                  {WEEKLY_MOOD_OPTIONS.map((option) => {
                     const {
                        iconName,
                        text,
                        value,
                        backgroundColor,
                        backgroundHoverColor,
                        mainColor,
                     } = option;

                     return (
                        <EmotionRadioCheckbox
                           key={value}
                           iconName={iconName}
                           text={text}
                           {...getRadioProps({ value: value.toString() })}
                           backgroundColor={backgroundColor}
                           backgroundHoverColor={backgroundHoverColor}
                           mainColor={mainColor}
                           onClick={handleSelectedMood}
                        />
                     );
                  })}
               </Box>
            </Flex>
         </BoxedModal>

         {/* Input How you feel Modal */}
         <BoxedModal
            bg="accent.sand"
            {...inputHowYouFeelTrackingModal}
            closeButtonBg="white"
            ctaLabel="Done"
            ctaStatus={trackMoodStatus}
            fullWidthBtn
            onCTAClick={handleDetailsModalNext}
            removeHorizontalPadding
            backButton={
               <IconButton
                  position="absolute"
                  variant="link"
                  display="flex"
                  width={12}
                  height={12}
                  icon={<Icon name="chevron-left" w={8} h={8} />}
                  aria-label="back-button"
                  mt={{ base: 3, lg: 8 }}
                  ml={{ base: 3, lg: 7 }}
                  borderRadius="50%"
                  backgroundColor="white"
                  onClick={handleBackToMoodSelection}
               />
            }
         >
            <Flex flex={1} direction="column" mt={4}>
               <Text size="paragraphLarge" mb={3}>
                  Last step, record your thoughts on why you feel the way you do
                  today.
               </Text>
               <Text mb={5} size="leadTitle">
                  I’m feeling {currentMoodInfo?.text.toLowerCase()} because...
               </Text>
               <Textarea
                  ref={textareaRef}
                  pl={4}
                  bg="white"
                  rows={4}
                  placeholder="Write your answer here"
                  onChange={handleDetailsInputChange}
                  value={detailsInput}
               />
            </Flex>
         </BoxedModal>

         {/* Success Mood Tracking Modal  */}
         <BoxedModal
            title={`Thanks for tracking your mood, ${sessionData?.given_name}!`}
            description="We hope you enjoy today’s journey"
            onCTAClick={onFinishMoodTrackingModals}
            ctaLabel="Done"
            textAlign="center"
            fullWidthBtn
            {...successMoodTrackingModal}
         >
            <Flex
               zIndex={0}
               position="absolute"
               bottom={0}
               right={0}
               top={0}
               left={0}
            >
               <NextImage
                  w="100%"
                  h="100%"
                  src="/images/modal-forest.png"
                  objectFit="cover"
                  alt="bg-succes-modal-image"
               />
            </Flex>
         </BoxedModal>
      </Box>
   );
};

export default DailyMoodTrackingModal;
