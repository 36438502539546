import {
   type EligibilityResponseDataDto,
   type GetEligibilityIntakeDto,
   isClaimMdExceptionPayload,
} from '@innerwell/dtos';
import { getStateFromTitle } from '@innerwell/us-states';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { DateTime } from 'luxon';

import useMyPatient from '@/hooks/react-query/useMyPatient';
import useThemedToast from '@/hooks/useThemedToast';

import { NextLink } from '@/components/NextLink/NextLink';

import { webApiClient } from '@/api-client/apiClient';
import { useSession } from '@/contexts/session-context';
import { formatDateForInsuranceEligibilityForm } from '@/utils/insurance';

import { EligibilityForm } from './EligibilityForm';

import { queryKeys } from '@/types/query-keys';

export const EligibilityFormIntake = ({
   onDone,
}: {
   onDone?: (data: EligibilityResponseDataDto) => void;
}) => {
   const queryClient = useQueryClient();
   const { patient } = useMyPatient();
   const { refetchSession } = useSession();

   const { toastSuccess, toastError } = useThemedToast();

   const { mutate: getEligibility, isPending } = useMutation({
      mutationFn: async (data: GetEligibilityIntakeDto) => {
         const response = await webApiClient.insurance.getEligibilityInIntake({
            body: {
               ...data,
               insured: {
                  ...data.insured,
                  dob: data.insured.dob?.toISO(),
               },
            },
         });

         return response.body;
      },
      onSuccess: (response) => {
         const data = response;
         if (data.guessedEligibility === true) {
            toastSuccess('You are eligible for insurance!');
            queryClient.invalidateQueries({
               queryKey: queryKeys.programs,
            });
            queryClient.invalidateQueries({
               queryKey: queryKeys.patient,
            });
            // Insurance data has changed
            refetchSession();
            onDone?.(data);
         } else {
            toastError(
               <div>
                  Sorry we were unable to verify your insurance. Please choose
                  to continue without insurance, or contact{' '}
                  <NextLink href="mailto:care@helloinnerwell.com">
                     care@helloinnerwell.com
                  </NextLink>{' '}
                  if you need further assistance.
               </div>,
            );
            // toastError(
            //    `You are not eligible for insurance. ${getClientFriendlyEligibilityError(data.guessedEligibility)}`,
            //    <div>
            //       Please choose to continue without insurance, or contact{' '}
            //       <NextLink href="mailto:care@helloinnerwell.com">
            //          care@helloinnerwell.com
            //       </NextLink>{' '}
            //       if you need further assistance.
            //    </div>,
            // );
         }
      },
      onError: (error) => {
         if (
            isAxiosError(error) &&
            isClaimMdExceptionPayload(error.response?.data)
         ) {
            toastError(
               'Incomplete or incorrect insurance data',
               error.response.data.errors.map((e) => e.error_mesg).join('\n'),
            );
            return;
         }

         toastError(
            <div>
               Sorry we were unable to verify your insurance. Please choose to
               continue without insurance, or contact{' '}
               <NextLink href="mailto:care@helloinnerwell.com">
                  care@helloinnerwell.com
               </NextLink>{' '}
               if you need further assistance.
            </div>,
         );
      },
   });

   if (!patient) {
      return null;
   }

   return (
      <EligibilityForm
         defaultValues={{
            stateAbbr: getStateFromTitle(patient.state ?? '')?.abbr,
            insured: {
               firstName: patient.firstName,
               lastName: patient.lastName,
               dob: patient.birthDate
                  ? formatDateForInsuranceEligibilityForm(
                       DateTime.fromISO(patient.birthDate),
                    )
                  : undefined,
            },
         }}
         onSubmit={getEligibility}
         isDisabled={isPending}
      />
   );
};
