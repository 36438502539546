import {
   Badge,
   Box,
   type BoxProps,
   ButtonGroup,
   Flex,
   IconButton,
   Skeleton,
   Text,
   useBoolean,
} from '@chakra-ui/react';
import {
   type AppointmentItem,
   CancelAppointmentIntent,
   CustomerIOEvents,
   SCHEDULING_ALLOWED_RESCHEDULING_BEFORE_APPOINTMENT_HOURS,
   TimetapLocationType,
   WelkinEncounterTemplateNames,
   WelkinPrograms,
} from '@innerwell/dtos';

import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { useMemo, useRef } from 'react';

import { useAppointment } from '@/hooks/react-query/useAppointment';
import { useAppointmentLoadingStatus } from '@/hooks/useAppointmentLoadingStatus';
import useThemedToast from '@/hooks/useThemedToast';

import { SkeletonWithLoader } from '@/components/Loaders';

import { webApiClient } from '@/api-client/apiClient';
import { usePatientProgram } from '@/contexts/patient-program-context';
import { USER_CAN_JOIN_MEET_BEFORE_MINUTES } from '@/utils/consts';
import {
   formatClinician,
   formatDateNicely,
   formatTimeRange,
   getTimezone,
} from '@/utils/formatting';

import { Card } from '@/components/Cards';
import CustomAvatar from '@/components/Avatar/Avatar';
import { Icon } from '@/components/Icon';

import { queryKeys } from '@/types/query-keys';
import { NextLinkButton } from '@/components/NextLinkButton/NextLinkButton';
import {
   getAppointmentReschedulePath,
   getErrorMessage,
   getGoogleMapsDirectionsUrl,
} from '@innerwell/utils';
import { InstallZoomAppPrompt } from './InstallZoomAppPrompt';
import { CancellationFeeDialog } from './CancellationFeeDialog';
import { useCancelAppointment } from '@/hooks/react-query/useCancelAppointment';
import { useRouter } from 'next/navigation';
import { handleSentryException, handleSentryMessage } from '@/utils/sentry';
import { useSession } from '@/contexts/session-context';
import { Button } from '../components/components';
import BoxedSandModal from '@/components/Modals/BoxedSandModal';
import { Tooltip } from '@/components/Tooltip/Tooltip';

interface JoinAppointmentCardProps extends BoxProps {
   title: string;
   appointment: AppointmentItem;
   size?: 'small' | 'large';
}

export const JoinAppointmentCard = ({
   title,
   appointment,
   size = 'large',
   ...props
}: JoinAppointmentCardProps) => {
   const { data: session } = useSession();
   const [
      isModifyDialogOpen,
      { on: openModifyDialog, off: closeModifyDialog },
   ] = useBoolean(false);
   const { push } = useRouter();
   const newWindowRef = useRef<Window | null>(null);
   const {
      programPhase: { program },
   } = usePatientProgram();

   const appointmentLoadingStatus = useAppointmentLoadingStatus();
   const { mutate: cancelAppointment, isPending: isCancellingAppointment } =
      useCancelAppointment();

   const { toastError, toastSuccess } = useThemedToast();

   const minutesAfterMeet = useMemo(() => {
      const minutesEndDiff = DateTime.now()
         .diff(DateTime.fromISO(appointment.endDateTime), 'minutes')
         .toObject().minutes;

      return minutesEndDiff || 0;
   }, [appointment.endDateTime]);

   const minutesUntilMeet = useMemo(() => {
      const minutesStartDiff = DateTime.fromISO(appointment.startDateTime)
         .diff(DateTime.now(), 'minutes')
         .toObject().minutes;

      return minutesStartDiff || 0;
   }, [appointment.startDateTime]);

   const clinician = formatClinician(appointment.clinician);

   const appointmentJoinUrlQuery = useQuery({
      queryKey: queryKeys.appointmentDisposition(appointment.id),
      queryFn: async () => {
         const response = await webApiClient.appointments.getJoinUrl({
            params: {
               id: appointment.id,
            },
         });

         const url = response.body;

         const getMeetingNumber = () => {
            if (url) {
               const m = /\/j\/(\d+)/.exec(url);
               return {
                  meetingUrl: url,
                  meetingNumber: m?.[1],
               };
            }

            return null;
         };

         const { meetingNumber, meetingUrl } = getMeetingNumber() ?? {};

         if (!meetingNumber) {
            toastError(
               'Cannot start meeting',
               'No Zoom meeting number available',
            );
            newWindowRef.current?.close();
            return;
         }

         if (meetingUrl && newWindowRef.current) {
            newWindowRef.current.location = meetingUrl;
         } else {
            toastError(
               'An error occurred, please try again or contact the support team',
            );
            newWindowRef.current?.close();
         }

         return url;
      },
      enabled: false,
   });

   const handleOpenMeeting = () => {
      // We first open the window/tab and then apply the link to the opened tab
      newWindowRef.current = window.open();

      appointmentJoinUrlQuery.refetch();
   };

   const { appointment: appointmentData, isLoading: isAppointmentDataLoading } =
      useAppointment({ appointmentId: appointment.id });

   const meetingLocationType = appointmentData?.location.locationType;

   const [
      isCancellationFeeDialogOpen,
      { on: openCancellationFeeDialog, off: closeCancellationFeeDialog },
   ] = useBoolean(false);

   const canModifyAppointmentFreeOfCharge =
      DateTime.now() < DateTime.fromISO(appointment.patientReschedulableUntil);

   const enableJoinMeetingButton =
      meetingLocationType === TimetapLocationType.Virtual &&
      minutesUntilMeet < USER_CAN_JOIN_MEET_BEFORE_MINUTES;

   const isAllLoaded =
      appointment.id !== appointmentLoadingStatus?.appointmentId &&
      !isAppointmentDataLoading;

   const cancelAppointmentWithoutFee = async () => {
      cancelAppointment(
         {
            appointmentId: appointment.id,
            intent: CancelAppointmentIntent.WithoutFee,
         },
         {
            onError: (error) => {
               handleSentryException(error);
               const errorMessage = getErrorMessage(error);
               toastError(errorMessage);
            },
            onSuccess: (response) => {
               if (!response.ok && response.feeRequired) {
                  openCancellationFeeDialog();
                  return;
               }

               closeModifyDialog();

               toastSuccess('Appointment canceled');
            },
         },
      );
   };

   const cancelAppointmentWithFee = () => {
      cancelAppointment(
         {
            appointmentId: appointment.id,
            intent: CancelAppointmentIntent.WithFee,
         },
         {
            onError: (error) => {
               handleSentryException(error);
               const errorMessage = getErrorMessage(error);
               toastError(errorMessage);
            },
            onSuccess: (response) => {
               webApiClient.customerio.track({
                  body: {
                     id: session?.['cognito:username'] ?? '',
                     name: CustomerIOEvents.CancelAppointmentConfirmed,
                     data: {
                        encounterId: appointment.id,
                     },
                  },
               });

               if (response.ok && response.missedPaymentCdtId) {
                  push(`/cancel-appointment/${response.missedPaymentCdtId}`);
               } else {
                  handleSentryMessage(
                     'Missed appointment cdt not created when cancelling with fee',
                     'fatal',
                  );
               }
            },
         },
      );
   };

   // If its medical consult and 5 minutes passed since the end of the meeting
   // Show new card
   if (
      program === WelkinPrograms.Intake &&
      appointment.appointmentTemplate ===
         WelkinEncounterTemplateNames.MedicalConsult &&
      minutesAfterMeet > 5
   ) {
      return (
         <Card mt={4}>
            <Card.Title>Medical Review in Progress</Card.Title>
            <Card.Text>
               Please give us 1-2 business days to review your case notes and
               confirm treatment eligibility. Once confirmed, we’ll be in touch
               when your prescription is on the way.
            </Card.Text>
            <Card.Image
               src="/images/medical-consult-woman.svg"
               alt="Background image"
            />
         </Card>
      );
   }

   return (
      <SkeletonWithLoader
         loadingText="Loading upcoming appointments..."
         isLoaded={isAllLoaded}
         borderRadius="12px"
         minH={!isAllLoaded ? '275px' : 'auto'}
         display="flex"
         flexDir="column"
      >
         <Box
            bg="linear-gradient(216.83deg, rgba(255, 156, 75, 0.12) 30.52%, rgba(255, 156, 75, 0) 91.09%), #FFFFFF"
            borderRadius={12}
            h="full"
            {...props}
         >
            <Box
               border="none"
               color="white"
               boxShadow="0px 5px 5px rgba(0, 0, 0, 0.15)"
               borderRadius="12px"
               h="full"
            >
               <Box
                  h="full"
                  sx={{
                     '.chakra-collapse': {
                        h: 'full !important',
                     },
                  }}
               >
                  <Flex p={0} h="full" flex={1}>
                     <Box
                        bg="linear-gradient(216.83deg, rgba(255, 156, 75, 0.12) 30.52%, rgba(255, 156, 75, 0) 91.09%), #FFFFFF"
                        borderRadius={12}
                        overflow="hidden"
                        h="full"
                     >
                        <Flex h="full" flex={1}>
                           <Flex
                              flex={{ base: 1 }}
                              direction="column"
                              m={{
                                 base: 5,
                                 lg: size === 'large' ? 10 : 4,
                              }}
                              mt={{
                                 base: 2,
                                 lg: size === 'small' ? 2 : 5,
                              }}
                           >
                              <Flex
                                 justifyContent="flex-start"
                                 alignItems="center"
                                 mb={4}
                              >
                                 <Text
                                    size="leadText"
                                    fontWeight={500}
                                    color="text.primary"
                                 >
                                    {title}
                                 </Text>

                                 {DateTime.fromISO(
                                    appointment.startDateTime,
                                 ).toISODate() ===
                                 DateTime.now().toISODate() ? (
                                    <Badge
                                       bg="accent.peach"
                                       color="accent.orange"
                                       rounded="full"
                                       fontWeight={600}
                                       fontSize="xxs"
                                       ml={2}
                                    >
                                       Today
                                    </Badge>
                                 ) : null}
                              </Flex>

                              <Flex
                                 gap={{
                                    base: 3,
                                    lg: size === 'large' ? 8 : 3,
                                 }}
                                 mr={{
                                    lg: size === 'small' ? 4 : 0,
                                 }}
                                 alignItems="flex-start"
                              >
                                 <Flex pos="relative">
                                    <CustomAvatar
                                       url={clinician.avatar}
                                       size="sm"
                                       desktopSize={
                                          size === 'large' ? 'md' : 'sm'
                                       }
                                       variant="square"
                                    />
                                    {meetingLocationType ===
                                    TimetapLocationType.Physical ? (
                                       <Icon
                                          name="in-person-badge"
                                          boxSize={{ base: 7, lg: 8 }}
                                          pos="absolute"
                                          right={-2}
                                          bottom={-2}
                                          color="accent.green"
                                       />
                                    ) : null}
                                 </Flex>

                                 <Flex
                                    direction="column"
                                    color="text.primary"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                 >
                                    <Skeleton
                                       isLoaded={Boolean(appointmentData)}
                                       borderRadius="12px"
                                       mb={{
                                          base: 3,
                                          lg: 2,
                                       }}
                                    >
                                       <Text
                                          lineHeight={1.3}
                                          fontWeight="600"
                                          fontSize={{
                                             base: 'md',
                                             lg: size === 'large' ? 'lg' : 'md',
                                          }}
                                       >
                                          {`${
                                             meetingLocationType ===
                                             TimetapLocationType.Physical
                                                ? 'In-office visit'
                                                : 'Video Consult'
                                          } with ${clinician.fullName}`}
                                       </Text>
                                    </Skeleton>
                                    {meetingLocationType ===
                                    TimetapLocationType.Physical ? (
                                       <Badge
                                          mt={-2}
                                          mb={1}
                                          whiteSpace="pre-wrap"
                                       >
                                          {
                                             appointmentData?.location
                                                .locationName
                                          }
                                       </Badge>
                                    ) : null}
                                    <Text fontSize="md">
                                       {formatDateNicely(
                                          appointment.startDateTime,
                                          { includeYear: false },
                                       )}
                                    </Text>
                                    <Text fontSize="md">
                                       {`${formatTimeRange({
                                          from: appointment.startDateTime,
                                          to: appointment.endDateTime,
                                       })} ${getTimezone()}`}
                                    </Text>
                                 </Flex>
                              </Flex>
                              <Flex
                                 pt={3}
                                 maxW={{
                                    lg: size === 'large' ? '340px' : '100%',
                                 }}
                                 flexDir="column"
                                 alignItems="flex-start"
                                 ml={{
                                    lg: size === 'large' ? '140px' : 0,
                                 }}
                                 mt={{
                                    lg:
                                       size === 'large'
                                          ? meetingLocationType ===
                                            TimetapLocationType.Physical
                                             ? '0'
                                             : '-10px'
                                          : 'auto',
                                 }}
                                 mb={{ base: 6, lg: 0 }}
                              >
                                 <ButtonGroup>
                                    <Tooltip
                                       label="Early access to the call will be available 15 minutes before its scheduled start time."
                                       isDisabled={enableJoinMeetingButton}
                                       shouldWrapChildren
                                    >
                                       {meetingLocationType ===
                                       TimetapLocationType.Virtual ? (
                                          <Button
                                             size="xs"
                                             onClick={() => {
                                                handleOpenMeeting();
                                             }}
                                             isDisabled={
                                                !enableJoinMeetingButton ||
                                                appointmentJoinUrlQuery.isFetching
                                             }
                                          >
                                             Join now
                                          </Button>
                                       ) : (
                                          <NextLinkButton
                                             href={getGoogleMapsDirectionsUrl(
                                                appointmentData?.location
                                                   .locationName ?? '',
                                             )}
                                             isDisabled={!appointmentData}
                                             size="xs"
                                             target="_blank"
                                          >
                                             Get directions
                                          </NextLinkButton>
                                       )}
                                    </Tooltip>

                                    <Button
                                       size="xs"
                                       variant="outline"
                                       onClick={openModifyDialog}
                                    >
                                       Modify
                                    </Button>
                                 </ButtonGroup>
                              </Flex>
                              <InstallZoomAppPrompt display={{ lg: 'none' }} />

                              <BoxedSandModal
                                 isOpen={isModifyDialogOpen}
                                 onClose={closeModifyDialog}
                                 closeOnEsc={!isCancellingAppointment}
                                 closeOnOverlayClick={!isCancellingAppointment}
                              >
                                 <IconButton
                                    variant="unstyled"
                                    position="absolute"
                                    maxW={6}
                                    right={0}
                                    top={0}
                                    icon={<Icon name="close" boxSize={5} />}
                                    aria-label="Close dialog"
                                    onClick={closeModifyDialog}
                                    hidden={isCancellingAppointment}
                                 />
                                 <BoxedSandModal.Heading mb={3} pt={8}>
                                    Need to adjust your plans?
                                 </BoxedSandModal.Heading>
                                 {!canModifyAppointmentFreeOfCharge && (
                                    <Text size="paragraphLarge">
                                       There is a $100 fee for canceling your
                                       appointment within{' '}
                                       {
                                          SCHEDULING_ALLOWED_RESCHEDULING_BEFORE_APPOINTMENT_HOURS
                                       }{' '}
                                       hours of its start time. This fee is
                                       charged out of respect for our
                                       clinicians’ time.
                                    </Text>
                                 )}
                                 <BoxedSandModal.ButtonGroup minW="full">
                                    {canModifyAppointmentFreeOfCharge ? (
                                       <NextLinkButton
                                          href={getAppointmentReschedulePath(
                                             appointment.id,
                                          )}
                                          size="xs"
                                       >
                                          Reschedule
                                       </NextLinkButton>
                                    ) : null}
                                    <Button
                                       variant={
                                          canModifyAppointmentFreeOfCharge
                                             ? 'outline'
                                             : 'solid'
                                       }
                                       isDisabled={isCancellingAppointment}
                                       isLoading={isCancellingAppointment}
                                       onClick={() => {
                                          if (
                                             canModifyAppointmentFreeOfCharge
                                          ) {
                                             cancelAppointmentWithoutFee();

                                             return;
                                          }
                                          cancelAppointmentWithFee();
                                       }}
                                    >
                                       Cancel appointment
                                    </Button>
                                 </BoxedSandModal.ButtonGroup>
                              </BoxedSandModal>

                              <CancellationFeeDialog
                                 isOpen={isCancellationFeeDialogOpen}
                                 onClose={closeCancellationFeeDialog}
                                 isCancellingAppointment={
                                    isCancellingAppointment
                                 }
                                 onConfirm={cancelAppointmentWithFee}
                              />
                           </Flex>
                        </Flex>
                     </Box>
                  </Flex>
               </Box>
            </Box>
         </Box>
      </SkeletonWithLoader>
   );
};
