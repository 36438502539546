import { Box, Flex, type FlexProps, Text } from '@chakra-ui/react';
import Lottie from 'lottie-react';
import { type Route } from 'next';

import { NextLinkButton } from '@/components/NextLinkButton/NextLinkButton';

import { useSession } from '@/contexts/session-context';
import animation from '@/lottie/lottie-doors-animation.json';
import { getClientPublicRuntimeConfig } from '@/services/env/utils/client-public-runtime-config';

import { Icon } from '../Icon';

export const PurchaseKetamineCard = ({ ...props }: FlexProps) => {
   const { data: sessionData } = useSession();

   const ketamineUpsellLink = sessionData?.insurance?.isValid
      ? (`/purchase/insurance/${getClientPublicRuntimeConfig().magentoInsuranceUpsellCatId}` satisfies Route<`/purchase/insurance/${string}`>)
      : (`/purchase/plan/${getClientPublicRuntimeConfig().magentoIntakeKetamineUpsellCatId}` satisfies Route<`/purchase/plan/${string}`>);

   return (
      <Flex
         direction="column"
         alignItems="center"
         color="text.primary"
         gap={5}
         maxW="35.75rem"
         mx="auto"
         textAlign="center"
         {...props}
      >
         <Box
            as={Lottie}
            animationData={animation}
            loop
            w={{ base: '3.9rem', lg: '5.7rem' }}
         />

         <Text size="leadText" fontWeight={600}>
            Interested in one of our ketamine programs?
         </Text>

         <Text>
            {`Our protocols are designed to harness ketamine's ability to increase
            neuroplasticity, your brain's ability to heal and form new
            connections. After 10 weeks of treatment, Innerwell patients on
            average experience a 69% reduction in depression symptoms and a 60%
            reduction in anxiety symptoms.`}
         </Text>

         <NextLinkButton
            href={ketamineUpsellLink}
            size="sm"
            icon={<Icon name="chevron-right" color="white" w={5} h={5} />}
            maxW="17rem"
         >
            See Programs
         </NextLinkButton>
      </Flex>
   );
};
