import { Flex, Text } from '@chakra-ui/react';
import { type Route } from 'next';

import { Icon } from '@/components/Icon';
import { FollowOnCardImage } from '@/components/Images';
import { NextLinkButton } from '@/components/NextLinkButton/NextLinkButton';

import { useSession } from '@/contexts/session-context';
import { getClientPublicRuntimeConfig } from '@/services/env/utils/client-public-runtime-config';

export const InterestedInKetamine = () => {
   const { data: sessionData } = useSession();

   const ketamineUpsellLink = sessionData?.insurance?.isValid
      ? (`/purchase/insurance/${getClientPublicRuntimeConfig().magentoInsuranceUpsellCatId}` satisfies Route<`/purchase/insurance/${string}`>)
      : (`/purchase/plan/${getClientPublicRuntimeConfig().magentoIntakeKetamineUpsellCatId}` satisfies Route<`/purchase/plan/${string}`>);

   return (
      <Flex
         bg="background.fourth"
         color="white"
         borderRadius="12px"
         overflow="hidden"
         flexDir={{ base: 'column-reverse', md: 'row' }}
      >
         <Flex p={6} flexDir="column" alignItems={{ md: 'flex-start' }}>
            <Flex flexDir="column" mb={6}>
               <Text size="leadText" fontWeight={600} mb={1.5}>
                  Interested in one of our ketamine programs?
               </Text>
               <Text>
                  Our protocols are designed to harness ketamine’s ability to
                  increase neuroplasticity, your brain’s ability to heal and
                  form new connections. After 10 weeks of treatment, Innerwell
                  patients on average experience a 69% reduction in depression
                  symptoms and a 60% reduction in anxiety symptoms.
               </Text>
            </Flex>

            <NextLinkButton
               href={ketamineUpsellLink}
               variant="outline"
               colorScheme="white"
               size={{ base: 'xs', lg: 'md' }}
               icon={
                  <Icon name="chevron-right" w={3.5} h={3.5} strokeWidth={2} />
               }
            >
               See Programs
            </NextLinkButton>
         </Flex>
         <Flex
            alignItems="center"
            justifyContent="center"
            minW="30%"
            minH="140px"
            bg="linear-gradient(216.83deg, rgba(255, 156, 75, 0.12) 30.52%, rgba(255, 156, 75, 0) 91.09%), #FFFFFF"
         >
            <FollowOnCardImage />
         </Flex>
      </Flex>
   );
};
