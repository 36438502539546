import { Accordion, Button, Divider, Flex, Skeleton } from '@chakra-ui/react';
import {
   IntakeProgramPhases,
   type PatientParamsResponseDto,
   TherapyPlan,
   type WelkinProgramPhases,
} from '@innerwell/dtos';
import { isIntakeProgramAfterOrEqual } from '@innerwell/utils';
import { useRouter } from 'next/navigation';
import { useEffect, useMemo, useState } from 'react';

import useMyClinician from '@/hooks/react-query/useMyClinician';
import useMyPatient from '@/hooks/react-query/useMyPatient';
import usePatientParams from '@/hooks/react-query/usePatientParams';
import useUpcomingAppointments from '@/hooks/react-query/useUpcomingAppointments';

import { Card } from '@/components/Cards';
import { InterestedInKetamine } from '@/components/Cards/InterestedInKetamine';
import HomeStepUpcomingProgress from '@/components/HomeSteps/HomeStepUpcomingProgress';
import { SkeletonWithLoader } from '@/components/Loaders';

import { usePatientProgram } from '@/contexts/patient-program-context';
import { formatClinician } from '@/utils/formatting';
import optionsLocalStorage from '@/utils/optionsLocalStorage';

import { AppointmentBank } from '../AppointmentBank/AppointmentBank';
import { PatientLocked } from '../Cards/PatientLocked/PatientLocked';
import { useUserAccess } from '../Cards/PatientLocked/useUserAccess';
import { DividerWithText } from '../Dividers';
import HomeCheckInsuranceEligibilityStep from '../HomeSteps/HomeCheckInsuranceEligibilityStep';
import { HomeConfirmContactConsent } from '../HomeSteps/HomeConfirmContactConsent';
import HomeWeeklyMoodTrackingStep from '../HomeSteps/HomeWeeklyMoodTrackingStep';
import { HomeOnboardingStep } from '../HomeSteps/Intake';
import MedicalConsultStep from '../HomeSteps/Intake/Onboarding/MedicalConsultStep';
import { StepAccordionWrapper } from '../HomeSteps/StepAccordionWrapper';
import { UpcomingAppointments } from '../Swipers/UpcomingAppointments';
import { PatientDischargedCard } from '../Cards/PatientDischargedCard';
import usePatientLastCarePlanChoice from '@/hooks/react-query/usePatientLastCarePlanChoice';
import { InsuranceStatusCard } from '../Cards/InsuranceStatusCard';

enum IntakeSteps {
   Onboarding,
   CheckEligibility,
   MedicalConsult,
   SetTreatmentPlan,
}

const getStep = (
   phase: WelkinProgramPhases | null,
   params: PatientParamsResponseDto | null,
) => {
   if (
      phase === IntakeProgramPhases.InformedConsentFormFinished ||
      phase === IntakeProgramPhases.DailyMoodTrackingFinished ||
      phase === IntakeProgramPhases.SetProgramGoalsFinished
   ) {
      return params?.shouldCheckInsuranceEligibility
         ? IntakeSteps.CheckEligibility
         : IntakeSteps.MedicalConsult;
   }

   // We're not sure until we have params whether to push into MedicalConsult or not
   if (
      isIntakeProgramAfterOrEqual(
         phase,
         IntakeProgramPhases.SetProgramGoalsFinished,
      )
   ) {
      return IntakeSteps.MedicalConsult;
   }

   return IntakeSteps.Onboarding;
};

export const IntakeLayout = () => {
   const {
      programPhase: { phase },
      patientHasFinishedInformedConsent,
   } = usePatientProgram();
   const { chosenCarePlan } = usePatientLastCarePlanChoice();

   const { push } = useRouter();

   const { params, isLoading: isLoadingPatientParams } = usePatientParams();

   const [isCompletedStepsHidden, setIsCompletedStepsHidden] = useState(() => {
      return optionsLocalStorage.getIsIntakeCompletedStepsHidden();
   });

   const { patient } = useMyPatient();

   const scheduleMeetTitle =
      chosenCarePlan === TherapyPlan.EMDR
         ? 'Schedule your EMDR Intake'
         : patient?.insurance?.planType === 'psychotherapist'
           ? 'Meet your Therapist'
           : 'Schedule your Medical Consult';

   const [currentStep, setCurrentStep] = useState<IntakeSteps>(() => {
      return getStep(phase, params);
   });

   // If phase changes because of invalidated query
   useEffect(() => {
      setCurrentStep(getStep(phase, params));
   }, [phase, params]);

   useEffect(() => {
      if (phase === IntakeProgramPhases.MedicalConsultReject) {
         push('/rejected/medical-consult');
      }
      if (phase === IntakeProgramPhases.MedicalIntakeRejected) {
         push('/rejected/intake');
      }
   }, [push, phase]);

   const { isLocked, statuses } = useUserAccess();

   const handleOnboardingCompleted = () => {
      if (patientHasFinishedInformedConsent === true) {
         setCurrentStep(IntakeSteps.MedicalConsult);
         return;
      }

      if (params?.shouldCheckInsuranceEligibility) {
         setCurrentStep(IntakeSteps.CheckEligibility);
         return;
      }

      setCurrentStep(IntakeSteps.MedicalConsult);
   };

   const stepsProgress = useMemo(() => {
      const progress: { [key in string]: 'upcoming' | 'completed' } = {
         onboarding: 'upcoming',
         medicalConsult: 'upcoming',
      };

      if (
         [IntakeSteps.MedicalConsult, IntakeSteps.CheckEligibility].includes(
            currentStep,
         )
      ) {
         progress.onboarding = 'completed';

         return progress;
      }

      return progress;
   }, [currentStep]);

   const handleHideCompletedStepsBtnClick = () => {
      setIsCompletedStepsHidden((prev) => {
         optionsLocalStorage.setIsIntakeCompletedStepsHidden(!prev);
         return !prev;
      });
   };

   const { clinician } = useMyClinician();
   const formattedClinician = clinician ? formatClinician(clinician) : null;

   const { appointments: upcomingAppointments, status: appointmentsStatus } =
      useUpcomingAppointments();

   return (
      <>
         {stepsProgress.dailyMood === 'completed' && (
            <HomeWeeklyMoodTrackingStep />
         )}

         <Flex flexDir="column" gap={{ base: 4, lg: 8 }}>
            <HomeConfirmContactConsent />

            {Boolean(patient?.isPatientDischarged) && <PatientDischargedCard />}

            <InsuranceStatusCard />
         </Flex>

         {isLocked ? (
            <PatientLocked statuses={statuses} />
         ) : phase === IntakeProgramPhases.ExtendedLayoutUnlocked ? (
            <>
               <Divider
                  borderBottom="2px solid"
                  borderColor="line.primary"
                  opacity={0.2}
                  mb={6}
               />

               <InterestedInKetamine />

               <DividerWithText
                  mt={{ base: 6, lg: 8 }}
                  mb={4}
                  text="Your upcoming appointments"
               />
               <SkeletonWithLoader
                  loadingText="Loading upcoming appointments..."
                  minH={
                     appointmentsStatus === 'pending' ||
                     (upcomingAppointments && upcomingAppointments.length)
                        ? '56px'
                        : 0
                  }
                  borderRadius="12px"
                  isLoaded={appointmentsStatus !== 'pending'}
                  mb={5}
               >
                  <UpcomingAppointments appointments={upcomingAppointments} />
               </SkeletonWithLoader>

               <AppointmentBank />
            </>
         ) : phase === IntakeProgramPhases.MedicalConsultOnly ? (
            <>
               <HomeWeeklyMoodTrackingStep />
               <Card>
                  <Card.Image
                     imagePosition="center"
                     alt={formattedClinician?.fullName ?? 'Avatar'}
                     src={
                        formattedClinician?.imageUrl ??
                        '/images/ben-medrano-peach.jpg'
                     }
                     borderRadius={
                        formattedClinician?.imageUrl ? '12px' : '50%'
                     }
                  />

                  <Card.Title>Let’s take the next step, together.</Card.Title>
                  {patient?.insurance?.isValid ? (
                     <Card.Text>
                        Your clinician has determined that you are a great fit
                        for a ketamine program at Innerwell.
                     </Card.Text>
                  ) : (
                     <Card.Text>
                        Your clinician has determined that you are a great fit
                        for a ketamine program at Innerwell.
                        <br />
                        <br />
                        Use code <strong>UPGRADE175</strong> to save $175 on
                        your purchase.
                     </Card.Text>
                  )}

                  <Card.ButtonGroup>
                     <Card.ButtonLink
                        href={
                           patient?.insurance?.isValid
                              ? `/purchase/insurance/36`
                              : '/plans/choose-a-plan'
                        }
                     >
                        Purchase now
                     </Card.ButtonLink>
                  </Card.ButtonGroup>
               </Card>

               <DividerWithText
                  mt={8}
                  mb={4}
                  text="Your upcoming appointments"
               />
               <SkeletonWithLoader
                  loadingText="Loading upcoming appointments..."
                  minH={
                     appointmentsStatus === 'pending' ||
                     (upcomingAppointments && upcomingAppointments.length)
                        ? '56px'
                        : 0
                  }
                  borderRadius="12px"
                  isLoaded={appointmentsStatus !== 'pending'}
                  mb={5}
               >
                  <UpcomingAppointments appointments={upcomingAppointments} />
               </SkeletonWithLoader>
            </>
         ) : (
            <Flex flexDir="column">
               {stepsProgress.onboarding === 'completed' && (
                  <Button
                     variant="link"
                     color="white"
                     onClick={handleHideCompletedStepsBtnClick}
                     mb={isCompletedStepsHidden ? 1 : 5}
                     fontSize="body"
                     ml="auto"
                  >
                     {isCompletedStepsHidden
                        ? 'Show completed'
                        : 'Hide completed'}
                  </Button>
               )}
               <Accordion index={[currentStep]}>
                  <StepAccordionWrapper
                     initialComponent={
                        !isCompletedStepsHidden ? (
                           <HomeStepUpcomingProgress
                              status="completed"
                              title="Onboarding"
                              variant="light"
                              fill="background.primary"
                              opacity={0.5}
                           />
                        ) : undefined
                     }
                     expandedComponent={
                        <HomeOnboardingStep
                           onCompleted={handleOnboardingCompleted}
                        />
                     }
                  />

                  <StepAccordionWrapper
                     initialComponent={
                        params?.shouldCheckInsuranceEligibility &&
                        !isCompletedStepsHidden ? (
                           <HomeStepUpcomingProgress
                              title="Check your insurance eligibility"
                              variant="light"
                              fill="background.primary"
                           />
                        ) : undefined
                     }
                     expandedComponent={
                        params?.shouldCheckInsuranceEligibility ? (
                           <HomeCheckInsuranceEligibilityStep
                              onSuccess={() => {
                                 setCurrentStep(IntakeSteps.MedicalConsult);
                              }}
                           />
                        ) : undefined
                     }
                  />

                  <StepAccordionWrapper
                     initialComponent={
                        <HomeStepUpcomingProgress
                           title={scheduleMeetTitle}
                           variant="light"
                           fill="background.primary"
                        />
                     }
                     expandedComponent={
                        <Skeleton
                           isLoaded={!isLoadingPatientParams}
                           borderRadius="lg"
                        >
                           <MedicalConsultStep />
                        </Skeleton>
                     }
                  />
               </Accordion>

               <HomeStepUpcomingProgress
                  title="Start your treatment plan"
                  variant="light"
                  fill="background.primary"
               />
            </Flex>
         )}
      </>
   );
};
