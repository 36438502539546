import {
   Box,
   Flex,
   Icon,
   type IconProps,
   Skeleton,
   type SkeletonProps,
   Text,
} from '@chakra-ui/react';

type Props = {
   loadingText?: string;
} & SkeletonProps;

const SquareSpinner = (props: IconProps) => {
   return (
      <Icon
         width="48px"
         height="48px"
         viewBox="0 0 48 48"
         fill="none"
         {...props}
      >
         <defs id="defs2375" />
         <rect
            x="24"
            y="8"
            width="16"
            height="16"
            fill="#FFD9BB"
            id="rect7093"
          />
         <rect x="24" y="8" width="16" height="16" fill="#FFD9BB" id="rect1958">
            <animateTransform
               attributeName="transform"
               attributeType="auto"
               type="translate"
               values="-16 16;-16 16;-16 0;0 0;0 16;-16 16;-16 16;-16 16"
               calcMode="spline"
               keyTimes="0;0.12502232541525268;0.25004465083050537;0.3750669762457582;0.4997320950169673;0.6251116270762637;0.7501339524915164;1"
               keySplines="0 0 1 1;0 0 1 1;0 0 1 1;0 0 1 1;0 0 1 1;0 0 1 1;0 0 1 1"
               dur="2.87s"
               begin="0s"
               repeatCount="indefinite"
               additive="sum"
               accumulate="none"
               fill="freeze"
               id="animateTransform9256"
             />
         </rect>
         <rect
            x="24"
            y="24"
            width="16"
            height="16"
            fill="#FFD9BB"
            id="rect2747"
         >
            <animateTransform
               attributeName="transform"
               attributeType="auto"
               type="translate"
               values="0 0;-16 0;-16 -16;0 -16;0 -16;0 0;0 0;0 0;0 0"
               calcMode="spline"
               keyTimes="0; 0.12502232541525274; 0.2500446508305055; 0.3750669762457582; 0.500089301661011; 0.6251116270762636; 0.7501339524915164; 0.8751562779067691; 1"
               keySplines="0 0 1 1;0 0 1 1;0 0 1 1;0 0 1 1;0 0 1 1;0 0 1 1;0 0 1 1;0 0 1 1"
               dur="2.87s"
               begin="0s"
               repeatCount="indefinite"
               additive="sum"
               accumulate="none"
               fill="freeze"
               id="animateTransform3274"
             />
         </rect>
         <rect x="24" y="8" width="16" height="16" fill="#FFD9BB" id="rect6597">
            <animateTransform
               attributeName="transform"
               attributeType="auto"
               type="translate"
               values="0 0;0 0;0 16;0 16;0 16;0 16"
               calcMode="spline"
               keyTimes="0;0.3750669762457582;0.4997320950169673;0.6251116270762636;0.6251116270762636;1"
               keySplines="0 0 1 1;0 0 1 1;0 0 1 1;0 0 1 1;0 0 1 1"
               dur="2.87s"
               begin="0s"
               repeatCount="indefinite"
               additive="sum"
               accumulate="none"
               fill="freeze"
               id="animateTransform4021"
             />
         </rect>
         <rect x="8" y="8" width="16" height="16" fill="#FFD9BB" id="rect9370">
            <animateTransform
               attributeName="transform"
               attributeType="auto"
               type="translate"
               values="0 0;0 0;0 0;16 0;16 16;0 16;0 0;0 0"
               calcMode="spline"
               keyTimes="0;0.125;0.24982142857142858;0.3748214285714286;0.4998214285714286;0.6248214285714285;0.75;1"
               keySplines="0 0 1 1;0 0 1 1;0 0 1 1;0 0 1 1;0 0 1 1;0 0 1 1;0 0 1 1"
               dur="2.87s"
               begin="0s"
               repeatCount="indefinite"
               additive="sum"
               accumulate="none"
               fill="freeze"
               id="animateTransform1892"
             />
         </rect>
         <rect
            x="24"
            y="24"
            width="16"
            height="16"
            fill="#1F545E"
            id="rect4194"
            transform="matrix(-1 -1.2246467596918116e-16 1.2246467596918116e-16 -1 64 64)"
         >
            <animateTransform
               attributeName="transform"
               attributeType="auto"
               type="translate"
               values="0 0;16 0;16 16;0 16;0 0;16 0;16 16;0 16;0 0"
               calcMode="spline"
               keyTimes="0; 0.125; 0.25; 0.37500000000000006; 0.5; 0.625; 0.7500000000000001; 0.8750000000000001; 1"
               keySplines="0 0 1 1;0 0 1 1;0 0 1 1;0 0 1 1;0 0 1 1;0 0 1 1;0 0 1 1;0 0 1 1"
               dur="2.87s"
               begin="0s"
               repeatCount="indefinite"
               additive="sum"
               accumulate="none"
               fill="freeze"
               id="animateTransform9627"
             />
         </rect>
      </Icon>
   );
};

export const SkeletonWithLoader = ({
   loadingText,
   ...skeletonProps
}: Props) => {
   return (
      <Box pos="relative">
         <Skeleton zIndex={1} {...skeletonProps} />
         {!skeletonProps.isLoaded && (
            <Flex
               alignItems="center"
               justifyContent="center"
               textAlign="center"
               flexDir="column"
               pos="absolute"
               left={0}
               top={0}
               w="full"
               h="full"
               zIndex={2}
            >
               <SquareSpinner />
               {loadingText ? (
                  <Text fontWeight={500} color="text.primary">
                     {loadingText}
                  </Text>
               ) : null}
            </Flex>
         )}
      </Box>
   );
};
