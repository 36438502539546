import { Box, type BoxProps } from '@chakra-ui/react';
import {
   CustomerIOEvents,
   IntakeProgramPhases,
   WelkinPrograms,
} from '@innerwell/dtos';
import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

import useThemedToast from '@/hooks/useThemedToast';

import { webApiClient } from '@/api-client/apiClient';
import { usePatientProgram } from '@/contexts/patient-program-context';
import { useSession } from '@/contexts/session-context';

import ConsentStep from './ConsentStep';
import IntakeStep from './IntakeStep';
import UploadIdStep from './UploadIdStep';

type Props = {
   onCompleted: () => void;
} & BoxProps;

const HomeOnboardingStep = ({ onCompleted, ...props }: Props) => {
   const { data: session } = useSession();

   const searchParams = useSearchParams();
   const {
      programPhase: { phase },
      updateProgramPhase,
   } = usePatientProgram();
   const { toastSuccess } = useThemedToast();

   const showMedicalIntake =
      phase === IntakeProgramPhases.HomeUnlocked ||
      phase === IntakeProgramPhases.HomeUnlockedPsychiatricClinician ||
      phase === IntakeProgramPhases.HomeUnlockedPsychotherapist ||
      phase === IntakeProgramPhases.MedicalIntakeStarted ||
      phase === IntakeProgramPhases.MedicalIntakeInProgress;

   const showUploadId =
      phase === IntakeProgramPhases.MedicalIntakeFinished ||
      phase === IntakeProgramPhases.MedicalIntakeAccepted ||
      phase === IntakeProgramPhases.UploadIdStarted;

   const showConsent =
      phase === IntakeProgramPhases.UploadIdFinished ||
      phase === IntakeProgramPhases.InformedConsentFormStarted;

   const isDocumentUploaded = searchParams.get('document_uploaded') === '1';
   const isConsentSigned = searchParams.get('consent_signed') === '1';

   useEffect(() => {
      if (!phase || !session) return;

      if (phase === IntakeProgramPhases.UploadIdStarted && isDocumentUploaded) {
         updateProgramPhase({
            program: WelkinPrograms.Intake,
            phase: IntakeProgramPhases.UploadIdFinished,
         });

         webApiClient.customerio.track({
            body: {
               id: session['cognito:username'],
               name: CustomerIOEvents.UploadIdFinished,
            },
         });

         toastSuccess('Thanks for your submission!');
      }

      if (
         phase === IntakeProgramPhases.InformedConsentFormStarted &&
         isConsentSigned
      ) {
         updateProgramPhase({
            program: WelkinPrograms.Intake,
            phase: IntakeProgramPhases.InformedConsentFormFinished,
         });
         onCompleted();

         webApiClient.customerio.track({
            body: {
               id: session['cognito:username'],
               name: CustomerIOEvents.OnboardingConsentSigned,
            },
         });

         toastSuccess('Consent completed!');
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [webApiClient, phase, isConsentSigned, isDocumentUploaded, session]);

   return (
      <Box {...props}>
         {showMedicalIntake ? <IntakeStep /> : null}
         {showUploadId ? <UploadIdStep /> : null}
         {showConsent ? <ConsentStep /> : null}
      </Box>
   );
};

export default HomeOnboardingStep;
