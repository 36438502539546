import { type FlexProps } from '@chakra-ui/react';

import { Card } from '@/components/Cards';
import { MissedAppointmentGraphic } from '@/components/Images';

export const MissedAppointmentPaymentCompletedCard = (props: FlexProps) => {
   return (
      <Card minH="none" bg="background.secondary" {...props}>
         <Card.Title
            fontWeight={600}
            display="flex"
            gap={3}
            alignItems="center"
         >
            <MissedAppointmentGraphic
               w={{ base: '60px', lg: '80px' }}
               h={{ base: '60px', lg: '80px' }}
            />
            We’re sorry we missed you.
         </Card.Title>
         <Card.Text fontSize="md" pr={4}>
            Thanks for your payment! Our Member Support team will be in touch
            within the next business day, if not sooner, to reopen your portal
            for scheduling with your Psychiatric Clinician.
         </Card.Text>
      </Card>
   );
};
