import {
   Accordion,
   AccordionButton,
   AccordionItem,
   AccordionPanel,
   Box,
   Flex,
   Skeleton,
   Text,
   useDisclosure,
} from '@chakra-ui/react';
import { useSearchParams } from 'next/navigation';

import { useDailyMoodTracking } from '@/hooks/react-query/useDailyMoodTracking';

import MoodTrackingWidget from '@/components/MoodTrackingWidget/MoodTrackingWidget';
import { NextLinkButton } from '@/components/NextLinkButton/NextLinkButton';

import { Icon } from '../Icon';
import { BottomRightCircles } from '../Images';
import DailyMoodTrackingModal from '../Modals/DailyMoodTrackingModal';
import { useState } from 'react';

const HomeWeeklyMoodTrackingStep = () => {
   const [dailyMoodTrackDate, setDailyMoodTrackDate] = useState<string | null>(
      null,
   );

   const searchParams = useSearchParams();

   const isWeeklyPauseFinished =
      searchParams.get('weekly-pause-finished') === '1';

   const initialMoodTrackingModal = useDisclosure();

   const {
      data: weeklyMoodProgressData,
      refetch: refetchMoodTracking,
      isRefetching,
      status,
   } = useDailyMoodTracking();

   const handleOpenMoodTrackingModalWithDate = (date: string) => {
      setDailyMoodTrackDate(date);
      initialMoodTrackingModal.onOpen();
   };

   const handleFinishedDailyMoodTracking = () => {
      refetchMoodTracking();
   };

   const sendWeeklyMoodTracking =
      !!weeklyMoodProgressData?.sendWeeklyPause && !isWeeklyPauseFinished;

   return status === 'pending' ? (
      <Skeleton h="156px" borderRadius="12px" mb={8} />
   ) : (
      <>
         <Skeleton
            isLoaded={!isRefetching}
            minH={isRefetching ? '158px' : 0}
            borderRadius="12px"
            mb={isRefetching ? 8 : 0}
            w="full"
            zIndex={5}
            pos="relative"
         >
            {weeklyMoodProgressData ? (
               <>
                  <MoodTrackingWidget
                     days={weeklyMoodProgressData?.segments}
                     onStartMoodTracking={handleOpenMoodTrackingModalWithDate}
                  />
                  {sendWeeklyMoodTracking ? (
                     <Box pos="relative" zIndex={4}>
                        <Flex
                           flexDir="column"
                           position="absolute"
                           top="-2.05rem"
                           left={5}
                           alignItems="center"
                           justifyContent="center"
                           zIndex={4}
                        >
                           <Icon
                              name="vertical-dashed-line"
                              h={14}
                              w="1px"
                              color="background.primary"
                           />

                           <Box
                              boxSize=".7rem"
                              bg="background.primary"
                              rounded="full"
                              opacity={1}
                              flexShrink={0}
                              flexGrow={0}
                           />
                        </Flex>
                        <Accordion allowToggle defaultIndex={[0]} mb={6}>
                           <AccordionItem
                              border="none"
                              borderRadius="12px"
                              boxShadow="0px 5px 5px rgba(0, 0, 0, 0.15)"
                           >
                              {({ isExpanded }) => (
                                 <>
                                    <h2>
                                       <AccordionButton
                                          px={{ base: 3, lg: 4 }}
                                          minH="56px"
                                          pl={{ base: 10, lg: '42px' }}
                                          bg="background.secondary"
                                          _hover={{
                                             bg: 'background.secondary',
                                          }}
                                          borderRadius="12px"
                                          transition="all .15s ease"
                                          borderBottomLeftRadius={
                                             isExpanded ? 0 : '12px'
                                          }
                                          borderBottomRightRadius={
                                             isExpanded ? 0 : '12px'
                                          }
                                          pos="relative"
                                          borderBottom="1px solid"
                                          borderColor="rgba(65, 119, 119, 0.1)"
                                       >
                                          <Text
                                             size="eyebrow"
                                             color="text.primary"
                                          >
                                             Weekly reflection
                                          </Text>
                                          <Icon
                                             name="chevron-down-light"
                                             w={5}
                                             h={5}
                                             color="text.primary"
                                             ml="auto"
                                             transition="transform .2s ease"
                                             transform={
                                                isExpanded
                                                   ? 'rotate(180deg)'
                                                   : 'rotate(0deg)'
                                             }
                                             pos="absolute"
                                             right={{ base: 3, lg: 4 }}
                                          />
                                       </AccordionButton>
                                    </h2>
                                    <AccordionPanel
                                       pt={4}
                                       pb={6}
                                       px={6}
                                       bg="background.secondary"
                                       borderBottomLeftRadius="12px"
                                       borderBottomRightRadius="12px"
                                       color="text.primary"
                                       pos="relative"
                                    >
                                       <Text mb={4}>
                                          These tailored questionnaires provide
                                          valuable insight into your treatment
                                          progress. Regularly measuring your
                                          progress enables you and your care
                                          team to work together to make
                                          personalized adjustment to your care
                                          plan.
                                       </Text>
                                       <NextLinkButton
                                          size="sm"
                                          href="/weekly-pause"
                                       >
                                          Begin
                                       </NextLinkButton>

                                       <BottomRightCircles
                                          pos="absolute"
                                          right={0}
                                          bottom={0}
                                          display={{
                                             base: 'none',
                                             lg: 'block',
                                          }}
                                       />
                                    </AccordionPanel>
                                 </>
                              )}
                           </AccordionItem>
                        </Accordion>
                     </Box>
                  ) : null}
               </>
            ) : null}
         </Skeleton>

         <DailyMoodTrackingModal
            initialMoodTrackingModal={initialMoodTrackingModal}
            w="full"
            onFinishedDailyMoodTracking={handleFinishedDailyMoodTracking}
            date={dailyMoodTrackDate}
         />
      </>
   );
};

export default HomeWeeklyMoodTrackingStep;
